body, html {
  height: 100%;
  margin: 0;
}
.bg {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top right;
  height: 100vh; /* Full viewport height */
  margin: 0; /* Removes default margin */
  padding: 0; /* Removes default padding */
}